// links
export const MAILTO: string = "mailto:";
export const MAILGO: string = "mailgo:";
export const TEL: string = "tel:";
export const CALLTO: string = "callto:";
export const SMS: string = "sms:";

// deep linking
export const outlookDeepLink: string = "ms-outlook://";

// no mailgo class
export const NO_MAILGO: string = "no-mailgo";

// mailgo types
export const MAILGO_MAIL: string = "mailgo";
export const MAILGO_TEL: string = "mailgo-tel";
export const MAILGO_SMS: string = "mailgo-sms";

// useful html tags
export const spanHTMLTag: string = "span";
export const aHTMLTag: string = "a";
export const pHTMLTag: string = "p";

// default lang
export const defaultLang: string = "en";

// events tag
export const mailgoReadyTag: string = "mailgo-ready";
export const mailgoRenderTag: string = "mailgo-render";
